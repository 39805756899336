var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.theme.colors.primary,"dark":""}},[_c('v-btn',{attrs:{"to":_vm.backRoute,"icon":"","exact":"","title":"Inapoi"}},[_c('v-icon',[_vm._v("arrow_back")])],1),_c('v-toolbar-title',[_vm._v("Editare fisier")]),_c('div',{staticClass:"flex-grow-1"}),void 0,_c('v-btn',{attrs:{"to":{ name: 'files.create' },"icon":"","exact":"","title":"Urca fisier"}},[_c('v-icon',[_vm._v("cloud_upload")])],1)],2),_c('validation-observer',{ref:"observer",on:{"submit":function($event){$event.preventDefault();return _vm.sendRequest($event)}}},[_c('v-form',[_c('v-row',{staticClass:"px-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"label","rules":_vm.form.label.validation.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"label-select",attrs:{"items":_vm.labels,"label":_vm.form.label.label,"error-messages":errors,"item-text":"name","item-value":"id","required":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{style:(_vm.paddingStyle(item.depth))},[_vm._v(_vm._s(item.name))])]}}],null,true),model:{value:(_vm.form.label.value),callback:function ($$v) {_vm.$set(_vm.form.label, "value", $$v)},expression:"form.label.value"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"name","rules":_vm.form.name.validation.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.form.name.label,"error-messages":errors,"required":""},model:{value:(_vm.form.name.value),callback:function ($$v) {_vm.$set(_vm.form.name, "value", $$v)},expression:"form.name.value"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"file","rules":_vm.form.file.validation.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"label":_vm.form.file.label,"error-messages":errors,"required":"","accept":"application/pdf"},model:{value:(_vm.form.file.value),callback:function ($$v) {_vm.$set(_vm.form.file, "value", $$v)},expression:"form.file.value"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"dateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.form.date.label,"persistent-hint":"","prepend-icon":"event"},on:{"blur":function($event){_vm.form.date.value = _vm.parseDate(_vm.form.date.formattedValue)}},model:{value:(_vm.form.date.formattedValue),callback:function ($$v) {_vm.$set(_vm.form.date, "formattedValue", $$v)},expression:"form.date.formattedValue"}},on))]}}]),model:{value:(_vm.form.date.menu),callback:function ($$v) {_vm.$set(_vm.form.date, "menu", $$v)},expression:"form.date.menu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.form.date.menu = false}},model:{value:(_vm.form.date.value),callback:function ($$v) {_vm.$set(_vm.form.date, "value", $$v)},expression:"form.date.value"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.form.indexed.label,"readonly":""},model:{value:(_vm.form.indexed.value),callback:function ($$v) {_vm.$set(_vm.form.indexed, "value", $$v)},expression:"form.indexed.value"}})],1)],1),_c('div',{staticClass:"grey lighten-4 pa-3"},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","type":"submit"}},[_vm._v("Actualizeaza")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }