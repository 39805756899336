<template lang="pug">
  v-container
    router-view(:labels="labels")
</template>
<script>
export default {
  data () {
    return {
      labels: []
    };
  },
  mounted () {
    this.loadLabels();
  },
  methods: {
    loadLabels () {
      return this.axios.get('/labels')
        .then(res => {
          this.labels = res.data.data;
        })
        .catch(e => {
          this.$error(e);
        });
    }
  }
};
</script>
