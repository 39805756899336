<template lang="pug">
  v-card
    v-toolbar(:color="theme.colors.primary" dark)
      v-btn(
        :to="{ name: 'labels.index' }"
        icon
        exact
        title="Inapoi"
      )
        v-icon arrow_back
      v-toolbar-title Fisier nou
    validation-observer(ref="observer" @submit.prevent="sendRequest")
      v-form
        v-row(class="px-5")
          v-col(cols="12")
            validation-provider(name="label" :rules="form.label.validation.rules" v-slot="{ errors }")
              v-select(
                v-model="form.label.value"
                :items="labels"
                :label="form.label.label"
                :error-messages="errors"
                item-text="name"
                item-value="id"
                required
                class="label-select"
              )
                template(#item="{ item }")
                  div(:style="paddingStyle(item.depth)") {{ item.name }}
          v-col(cols="12")
            validation-provider(name="name" :rules="form.name.validation.rules" v-slot="{ errors }")
              v-text-field(
                v-model="form.name.value"
                :label="form.name.label"
                :error-messages="errors"
                required
              )
          v-col(cols="12")
            validation-provider(name="file" :rules="form.file.validation.rules" v-slot="{ errors }")
              v-file-input(
                @change="setFileToUpload"
                v-model="form.file.value"
                :label="form.file.label"
                :error-messages="errors"
                required
                accept="application/pdf"
              )
          v-col(cols="12")
            v-menu(
              ref="dateMenu"
              v-model="form.date.menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            )
              template(v-slot:activator="{ on }")
                v-text-field(
                  v-model="form.date.formattedValue"
                  :label="form.date.label"
                  persistent-hint
                  prepend-icon="event"
                  @blur="form.date.value = parseDate(form.date.formattedValue)"
                  v-on="on"
                )
              v-date-picker(v-model="form.date.value" no-title @input="form.date.menu = false")
        div(class="grey lighten-4 pa-3")
          v-card-actions
            v-spacer
            v-btn(color="success" type="submit") Salveaza
</template>
<script>
import { mapState } from 'vuex';
export default {
  props: {
    labels: {
      type: Array,
      required: true
    }
  },
  data: vm => ({
    dialog: true,
    form: {
      label: {
        value: null,
        label: 'Eticheta',
        validation: {
          rules: {
            required: true
          }
        }
      },
      name: {
        value: null,
        label: 'Nume',
        validation: {
          rules: {
            required: true
          }
        }
      },
      file: {
        value: null,
        label: 'Fisier',
        validation: {
          rules: {
            required: true,
            ext: ['pdf'],
            mimes: ['application/pdf'],
            size: 20480
          }
        }
      },
      date: {
        value: new Date().toISOString().substr(0, 10),
        formattedValue: vm.formatDate(new Date().toISOString().substr(0, 10)),
        label: 'Data adaugarii',
        menu: false
      }
    }
  }),
  computed: {
    ...mapState({
      user: s => s.auth.user,
      theme: s => s.config.theme
    })
  },
  watch: {
    'form.date.value' (v) {
      this.form.date.formattedValue = this.formatDate(v);
    }
  },
  methods: {
    paddingStyle (depth) {
      return depth ? `padding:12px 16px 12px ${depth * 16}px` : '';
    },
    getBody () {
      return {
        label: this.form.label.value,
        name: this.form.name.value,
        file: this.form.file.value,
        date: this.form.date.value
      };
    },
    getFormData () {
      const data = new FormData();
      const body = this.getBody();

      Object.entries(body).forEach(([field, value]) => {
        data.append(field, value);
      });

      return data;
    },
    setFileToUpload (file) {
      if (file) {
        this.form.file.value = file;
      } else {
        this.form.file.value = null;
      }
    },
    sendRequest () {
      return this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.$store.commit('pageLoading', true);

          return this.axios.post(
            `institutions/${this.user.institution.id}/files`,
            this.getFormData(),
            {
              headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then(res => {
              this.$success('Fisierul a fost urcat.');

              this.$router.push({ name: 'files.edit', params: { id: res.data.data.id } });
            })
            .catch(e => {
              if (e.response && e.response.data.status_code === 422 && e.response.data.hasOwnProperty('errors')) {
                this.$refs.observer.setErrors(e.response.data.errors);
              } else {
                this.$error(e);
              }
            })
            .finally(() => {
              this.$store.commit('pageLoading', false);
            });
        }
      });
    },
    formatDate (date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate (date) {
      if (!date) return null;

      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
  }
};
</script>
<style>
  .label-select .v-select__selections {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
